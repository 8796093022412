import { SET_LAST_RL } from '../actions/readingListActions';

const initialState = {
  setLastRL: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LAST_RL: {
      return {
        ...state,
        setLastRL: action.payload,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import { Fade, Grid, LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: '0',
    zIndex: '10000',
    left: '0',
    right: '0',
  },
}));

const LinearProgressMosaic = ({ loading }) => {
  const classes = useStyles();
  return (
    <Fade in={loading}>
      <Grid>
        <LinearProgress className={classes.root} color="secondary" />
      </Grid>
    </Fade>
  );
};

LinearProgressMosaic.defaultProps = {
  loading: true,
};

LinearProgressMosaic.propTypes = {
  loading: PropTypes.bool,
};

export default LinearProgressMosaic;

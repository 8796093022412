/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { ReactComponent as VariousJobsOnlineImage } from '../../assets/images/variousJobsOnline.svg';
import { ReactComponent as ReviewApllicantsImage } from '../../assets/images/reviewApllicants.svg';
import { SEND_INVITES_TO_JOIN_MUTATION } from '../../queries/interview/interview';

const useStyles = makeStyles((theme) => ({
  closeDialog: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  dialogContent: {
    padding: '10px 15px 0',
    [theme.breakpoints.up('md')]: {
      padding: '10px 24px 0',
    },
  },
  dialogContentFirst: {
    padding: '20px 15px 0',
    [theme.breakpoints.up('md')]: {
      padding: '20px 24px 0',
    },
  },
  dialogMessageTitle: {
    width: '100%',
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '32px',
    textAlign: 'center',
    marginTop: '32px',
  },
  dialogMessageText: {
    width: '100%',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '10px',
  },
  dialogActions: {
    padding: '32px 15px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '32px 24px 24px',
    },
  },
  dialogActionsFirst: {
    padding: '32px 15px 15px',
    [theme.breakpoints.up('md')]: {
      padding: '32px 24px 24px',
    },
  },
  confirmBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '10px',
    marginLeft: '18px !important',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  closeDialogBtn: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '10px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  invitesInput: {
    width: '100%',
    marginTop: '32px',
  },
  addedEmail: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    backgroundColor: theme.palette.info.light,
    color: theme.palette.text.primary,
    padding: '6px 6px 6px 12px',
    marginTop: '10px',
    marginRight: '10px',
    borderRadius: '5px',
  },
  closeBtn: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    marginLeft: '4px',
    color: theme.palette.text.secondary,
  },
  emailFieldset: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    border: 'none',
  },
  emailInput: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    width: '100%',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    padding: '10px 12px',
    height: '40px',
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.info.dark,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
  },
  iconBtn: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '18px',
    padding: '7px',
    marginLeft: '10px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.info.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&:active': {
      borderColor: theme.palette.info.main,
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const InterviewGuests = ({
  className, interviewId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [send, setSend] = useState(false);
  const [indexes, setIndexes] = useState([0]);
  const [counter, setCounter] = useState(0);

  const [sendInvites, {
    loading,
  }] = useMutation(SEND_INVITES_TO_JOIN_MUTATION, {
    errorPolicy: 'all',
  });

  const handleClick = () => {
    document.getElementById('formSubmit').click();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSend(false);
    setIndexes([0]);
    setCounter(0);
  };

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (formData, e) => {
    e.target.reset();
    setIndexes([0]);
    setCounter(0);
    sendInvites({
      variables: {
        emails: Object.values(formData),
        interviewId,
      },
    }).then(({ data }) => {
      if (data?.sendInvitesToJoin?.validatedEmails.length > 0) {
        setSend(true);
      } else {
        // eslint-disable-next-line no-console
        console.log('please, enter valid email');
      }
    });
  };

  const addFriend = () => {
    setIndexes([...indexes, counter + 1]);
    setCounter(counter + 1);
  };

  const removeFriend = (index) => () => {
    setIndexes([...indexes.filter((item) => item !== index)]);
    setCounter(counter - 1);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="text"
        className={className}
        disableRipple
      >
        Invite a Guest
      </Button>
      <Dialog onClose={handleClose} open={open}>
        <MuiDialogTitle disableTypography>
          <IconButton
            aria-label="close"
            className={classes.closeDialog}
            onClick={handleClose}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        {send
          ? (
            <>
              <MuiDialogContent className={classes.dialogContent}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <ReviewApllicantsImage />
                  <Box className={classes.dialogMessageTitle}>
                    Invite successfully sent to guests
                  </Box>
                </Box>
              </MuiDialogContent>
              <MuiDialogActions className={classes.dialogActions}>
                <Button
                  autoFocus
                  onClick={handleClose}
                  disableRipple
                  className={classes.confirmBtn}
                >
                  OK
                </Button>
              </MuiDialogActions>
            </>
          )
          : (
            <>
              <MuiDialogContent className={classes.dialogContentFirst}>
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <VariousJobsOnlineImage />
                  <Box className={classes.dialogMessageTitle}>
                    Share this transcript with a friend
                  </Box>
                  <Box className={classes.dialogMessageText}>
                    Invite a guest to join you on Stream.
                    Your guest will receive access to this transcript for free for 7 days.
                    You can invite each guest once.
                  </Box>
                  <Box className={classes.invitesInput}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {indexes.map((index) => {
                        const fieldName = `friends[${index}]`;
                        return (
                          <fieldset name={fieldName} key={fieldName} className={classes.emailFieldset}>
                            <input
                              type="email"
                              required
                              name={`${fieldName}.email`}
                              placeholder="Enter Email"
                              {...register(`field-${index}`, {
                                shouldUnregister: true,
                              })}
                              className={classes.emailInput}
                            />
                            <IconButton onClick={removeFriend(index)} disableRipple className={classes.iconBtn} style={{ display: (index !== 0) ? 'block' : 'none' }}>
                              <DeleteForeverIcon />
                            </IconButton>
                            <IconButton onClick={addFriend} disableRipple className={classes.iconBtn}>
                              <AddIcon />
                            </IconButton>
                          </fieldset>
                        );
                      })}
                      <input id="formSubmit" hidden type="submit" />
                    </form>
                  </Box>
                </Box>
              </MuiDialogContent>
              <MuiDialogActions className={classes.dialogActionsFirst}>
                <Button
                  onClick={handleClose}
                  disableRipple
                  className={classes.closeDialogBtn}
                >
                  Close
                </Button>
                <Button
                  onClick={handleClick}
                  disableRipple
                  className={classes.confirmBtn}
                >
                  Send Invites
                </Button>
              </MuiDialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

InterviewGuests.defaultProps = {
  className: '',
};

InterviewGuests.propTypes = {
  className: PropTypes.string,
  interviewId: PropTypes.string.isRequired,
};

export default InterviewGuests;

import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  link: {
    padding: 0,
    fontSize: '16px',
    textTransform: 'none',
    minWidth: 'unset',
    lineHeight: '20px',
    marginTop: '-4px',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
      backgroundColor: 'transparent;',
    },
    '&:active': {
      color: theme.palette.secondary.light,
    },
  },
}));

const Person = ({ purpose, name, onClick }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      mb="8px"
      mr="15px"
    >
      <Box
        fontSize="16px"
        lineHeight="20px"
      >
        <Box
          component="span"
          mr="4px"
        >
          {purpose}
          :
        </Box>
        <Box
          component="span"
          fontWeight="bold"
        >
          <Button disableRipple onClick={onClick} className={classes.link}>
            {name}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

Person.propTypes = {
  purpose: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Person;

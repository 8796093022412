// eslint-disable-next-line react/prop-types
import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types,no-unused-vars

const SentryFallback = ({ resetError }) => {
  const handleClick = () => {
    window.location.href = '/';
    resetError();
  };

  return (
    <Box className="sentryError-layout">
      <Box className="sentryError-content">
        <Box className="sentryError-img">
          <img alt="interview not found" src="/images/interview_not_found.svg" />
        </Box>
        <Box className="sentryError-message">
          <Box mb={{ xs: '15px', md: '25px' }}>
            You have encountered an error
          </Box>
          <Button className="sentryError-btn" onClick={handleClick}>
            Go to Home Page
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SentryFallback.defaultProps = {
  resetError: () => {},
};

SentryFallback.propTypes = {
  resetError: PropTypes.func,
};

export default SentryFallback;

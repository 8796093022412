import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  smallRect: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
  },
  bigRect: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    marginTop: '10px',
    padding: '10px',
  },
  mr5: {
    marginRight: '5px',
    borderRadius: '5px',
    backgroundColor: theme.palette.info.main,
  },
  withChildren: {},
}));

const InterviewItemSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton className={classes.smallRect} variant="rect" width="100%" height="30px" />
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.bigRect} variant="rect" width="100%" height="75px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="14px">
          <Box display="flex">
            <Skeleton className={classes.mr5} variant="rect" width="30px" height="10px" />
            <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
          </Box>
          <Skeleton className={classes.smallRect} variant="rect" width="80px" height="10px" />
        </Box>
        <Box display="flex" mb="9px">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
        <Box display="flex">
          <Skeleton className={classes.smallRect} variant="rect" width="100%" height="10px" />
        </Box>
      </Skeleton>
    </>
  );
};

export default InterviewItemSkeleton;

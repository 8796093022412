import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette.text.primary,
    '& ul': {
      paddingLeft: '1.3rem',
    },
  },
  button: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    color: theme.palette.secondary.main,
    padding: '0',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.dark,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
    },
  },
}));

const CollapseInfo = ({
  children, lines, more, less, isExpanded, className,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(isExpanded);

  const toggleLines = () => {
    setExpanded(!expanded);
  };

  const trimText = children.length > 260;

  return (
    <Box
      display="flex"
      flexDirection="column"
      ml="15px"
      className={className}
    >
      {(!expanded && trimText)
        ? (
          <>
            <HTMLEllipsis
              unsafeHTML={children}
              maxLine={lines}
              ellipsis="..."
              basedOn="letters"
              className={classes.text}
            />
            <Box>
              <Button
                onClick={toggleLines}
                disableRipple
                className={classes.button}
              >
                {more}
              </Button>
            </Box>
          </>
        )
        : (
          <Box
            dangerouslySetInnerHTML={{ __html: children }}
            className={classes.text}
          />
        )}
      {(expanded && trimText) && (
        <Box>
          <Button
            onClick={toggleLines}
            disableRipple
            className={classes.button}
          >
            {less}
          </Button>
        </Box>
      )}
    </Box>
  );
};

CollapseInfo.defaultProps = {
  isExpanded: false,
  lines: 2,
  more: 'Read more',
  less: 'Show less',
  className: '',
};

CollapseInfo.propTypes = {
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
  className: PropTypes.string,
};

export default CollapseInfo;

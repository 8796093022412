import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: '1',
  },
  content: {
    position: 'relative',
    width: '100%',
    maxWidth: '580px',
    padding: '20px',
    textAlign: 'center',
  },
  img: {
    width: '100%',
    marginBottom: '30px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '60px',
    },
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  message: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '25px',
    color: theme.palette.text.primary,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '24px',
      lineHeight: '30px',
    },
    '& a': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
      '&:active': {
        color: theme.palette.primary.light,
      },
    },
  },
}));

const InterviewNotFound = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.img}>
          <img alt="interview not found" src="/images/interview_not_found.svg" />
        </Box>
        <Box className={classes.message}>
          <Box mb={{ xs: '15px', md: '25px' }}>
            Sorry, we can&apos;t find the page you&apos;re looking for.
          </Box>
          <Box>
            Try checking the url, or go back to the
            {' '}
            <Link to="/">
              home page
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InterviewNotFound;

import { gql } from '@apollo/client';

export const TICKERS_OPTIONS_QUERY = gql`
query allTickers($first: Int, $skip: Int, $search: String) {
  allTickers(order: "title", first: $first, skip: $skip, search: $search) {
    totalCount
    results {
      id
      uuid
      title
      description
    }
  }
}
`;

export const EXPERTS_OPTIONS_QUERY = gql`
query experts($first: Int, $skip: Int, $search: String) {
  experts: allUsers(role: ["expert"], hasInterviews: true, first: $first, skip: $skip, search: $search){
    totalCount
    results {
      id
      uuid
      role
      shortName
    }
  }
}
`;

export const INTERVIEW_PERSPECTIVE_OPTIONS_QUERY = gql`
{
  allInterviewPerspective{
    totalCount
    results{
      id
      uuid
      perspective
    }    
  }
}
`;

export const INDUSTRIES_OPTIONS_QUERY = gql`
query allIndustries($first: Int, $skip: Int, $search: String) {
  allIndustries(first: $first, skip: $skip, search: $search) {
    totalCount
    results{
      id
      uuid
      title
    }
  }
}
`;
export const SECTORS_OPTIONS_QUERY = gql`
query allSectors($first: Int, $skip: Int, $search: String) {
  allSectors(first: $first, skip: $skip, search: $search) {
    totalCount
    results{
      id
      uuid
      title
    }
  }
}
`;

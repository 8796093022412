import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import PrintBtn from '../../../../components/InterviewPreviewComponents/PrintBtn';
import InterviewReadingList from '../InterviewPreview/components/InterviewActions/InterviewReadingList';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    zIndex: '3',
  },
  actionBtn: {
    width: '100%',
    height: '30px',
    padding: '5px 8px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    borderRadius: '5px',
    borderColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'inherit',
    cursor: 'pointer',
    '& svg path': {
      fill: theme.palette.common.white,
    },
    '& svg rect': {
      fill: theme.palette.common.white,
    },
    '&:hover': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.dark,
      '& svg path': {
        fill: theme.palette.common.white,
      },
      '& svg rect': {
        fill: theme.palette.common.white,
      },
    },
    '&:active': {
      color: theme.palette.common.white,
      borderColor: theme.palette.primary.light,
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
const BulkActions = ({ selected, isReadingList }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selected.length > 10 && isReadingList) {
      enqueueSnackbar('You can select a maximum of 10 transcripts to print', { variant: 'error', autoHideDuration: 4000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <Box width="100%" id="bulk-actions">
      <Box className={classes.container}>
        {selected.length > 0
        && (
          <Box mb="16px">
            {isReadingList
              ? (
                <PrintBtn
                  className={classes.actionBtn}
                  selected={selected}
                >
                  {`Print ${selected.length} selected to PDF`}
                </PrintBtn>
              )
              : (
                <InterviewReadingList
                  interviewIds={selected}
                  className={classes.actionBtn}
                />
              )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

BulkActions.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  isReadingList: PropTypes.bool.isRequired,
};

export default BulkActions;

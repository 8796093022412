import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { Footer, Topbar } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '71px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 189px)',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 171px)',
    },
  },
}));

const MainWhite = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar />
      <main className={classes.content}>
        {children}
      </main>
      <Footer />
    </Box>
  );
};

MainWhite.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default MainWhite;

import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { INTERVIEW_QUERY, ORDER_INTERVIEW_MUTATION } from '../../queries/interview/interview';
import { searchToObject } from '../../helpers/locationSearchToJson';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'PT Serif',
  },
  accessRequest: {
    //
  },
  postBox: {
    backgroundColor: '#1168A7',
    padding: '30px 20px',
    margin: '20px 0',
    color: '#fff',
    textAlign: 'center!important',
  },
  accessRequestHeadline: {
    fontFamily: 'PT Serif Caption!important',
    marginBottom: '16px',
    color: '#FFFFFF',
    fontSize: '20px!important',
    lineHeight: '26px',
    marginTop: '0',
    fontWeight: '700!important',
  },
  accessRequestText: {
    fontFamily: 'PT Serif Caption!important',
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '20px',
    fontWeight: '700!important',
  },
  accessRequestButton: {
    height: '40px',
    color: '#182341',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '11px 20px',
    background: '#fff',
    borderColor: '#1168A7',
    borderRadius: '0px',
    textTransform: 'none',
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '&:hover': {
      background: '#DAEFFF',
    },
  },
}));

const OrderInterview = ({ interviewId }) => {
  const classes = useStyles();
  const location = useLocation();
  const { trackEvent } = useTracking();

  const [createOrder] = useMutation(ORDER_INTERVIEW_MUTATION, {
    errorPolicy: 'all',
    refetchQueries: [
      { query: INTERVIEW_QUERY, variables: { interviewId } },
    ],
  });

  const handleRequest = () => {
    createOrder({ variables: { interviewId } });
    trackEvent({
      viewName: 'interview:order_interview',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({ interviewId }),
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.accessRequest}>
        <Box className={classes.postBox}>
          <Box className={classes.accessRequestHeadline}>
            This interview is in our transcription queue.
          </Box>
          <Box className={classes.accessRequestText}>
            Please click on the button below to indicate your interest and we will
            prioritize its transcription.
            <br />
            The interview will be available
            within 24 hrs once you do that.
          </Box>
          <Button onClick={handleRequest} className={classes.accessRequestButton}>
            Expedite Transcription
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

OrderInterview.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default OrderInterview;

import React from 'react';
import Box from '@material-ui/core/Box';
import { useLocation, useHistory } from 'react-router-dom';
import { usePagination } from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const ICONS = {
  first: `<svg class="paginationIcon" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 9L1 5L5 1" stroke="#182341" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 9L7 5L11 1" stroke="#182341" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>`,
  previous: `<svg class="paginationIcon" width="12" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5 9L1 5L5 1" stroke="#182341" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`,
  next: `<svg class="paginationIcon" width="12" height="10" viewBox="2 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M7 1L11 5L7 9" stroke="#182341" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>`,
  last: `<svg class="paginationIcon" width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M7 1L11 5L7 9" stroke="#182341" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
           <path d="M1 1L5 5L1 9" stroke="#182341" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>`,
};

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'flex',
    listStyleType: 'none',
  },
  listItem: {
    margin: '0 5px',
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    outline: 'none',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  currentBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '18px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const PaginationLink = ({ totalCount, initialFirst, onClick }) => {
  const count = Math.ceil(totalCount / initialFirst);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();
  const intFirst = parseInt(query.get('first') || initialFirst, 10);
  const intSkip = parseInt(query.get('skip') || 0, 10);
  const paginationPage = intSkip === 0 ? 1 : Math.ceil(intSkip / intFirst + 1);
  const classes = useStyles();

  const handleChange = (event, page) => {
    onClick(page);
    history.push({ search: `?first=${intFirst}&skip=${page * intFirst - intFirst}` });
  };

  const { items } = usePagination({
    count,
    page: paginationPage,
    // showFirstButton: true,
    // showLastButton: true,
    siblingCount: 1,
    boundaryCount: 1,
    onChange: handleChange,
  });

  if (!count || count <= 1) return '';

  return (
    <Box
      display="flex"
      justifyContent="center"
      mt="20px"
      mb="24px"
    >
      <ul className={classes.list}>
        {items.map(({
          page, type, selected, ...item
        }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button type="button" className={selected ? classes.currentBtn : classes.listBtn} {...item}>
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" className={classes.listBtn} {...item} dangerouslySetInnerHTML={{ __html: ICONS[type] }} />
            );
          }

          return <li key={index} className={classes.listItem}>{children}</li>;
        })}
      </ul>
    </Box>
  );
};

PaginationLink.defaultProps = {
  initialFirst: 10,
  onClick: () => {},
};

PaginationLink.propTypes = {
  totalCount: PropTypes.number.isRequired,
  initialFirst: PropTypes.number,
  onClick: PropTypes.func,
};

export default PaginationLink;

/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  ALL_READING_LISTS_QUERY,
  CREATE_READING_LIST_MUTATION,
} from '../../../../../../../../../queries/interview/interview';
import useReadingListTitle from '../../../../../../../../../hooks/useReadingListTitle';
import { ReactComponent as DoneFilledSuccessIcon } from '../../../../../../../../../assets/icons/doneFilledSuccessIcon.svg';
import { ReactComponent as AddRectIcon } from '../../../../../../../../../assets/icons/addRectIcon.svg';

const CreateReadingListField = withStyles((theme) => ({
  root: {
    marginBottom: '10px',
    '& .MuiInputBase-root': {
      borderRadius: '5px',
      height: '30px',
      paddingRight: '0px',
      '& input': {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        paddingLeft: '10px',
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
      },
      '& button': {
        padding: '10px',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
        borderStyle: 'dashed',
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.palette.primary.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.primary.main,
          },
        },
      },
      '&.Mui-disabled': {
        '& input': {
          color: theme.palette.text.secondary,
          backgroundColor: theme.palette.background.default,
        },
        '& fieldset': {
          borderColor: theme.palette.info.main,
          borderWidth: '1px',
        },
        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.info.main,
          },
        },
      },
    },
  },
}))(TextField);

const CreateReadingList = ({
  startAdornment, placeholder, handleAddInterview, ...style
}) => {
  // save input value from creation RL field
  const [inputValue, setInputValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [createReadingList] = useMutation(CREATE_READING_LIST_MUTATION, {
    onCompleted: (data) => {
      window.analytics.track('Transcript Saved');
      handleAddInterview(data.createReadingList.readingList.id)();
    },
  });

  // update values mentioned below
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setInputValue(value);
  };

  const readingListTitle = useReadingListTitle();

  const handleCreate = () => {
    if (!inputValue) {
      enqueueSnackbar(`${readingListTitle} name is required!`, { variant: 'error', autoHideDuration: 4000 });
      return;
    }
    createReadingList({
      variables: {
        title: inputValue.trim(),
      },
      update: ((cache, { data }) => {
        const { allReadingList } = cache.readQuery({ query: ALL_READING_LISTS_QUERY });
        cache.writeQuery({
          query: ALL_READING_LISTS_QUERY,
          data: {
            allReadingList: {
              ...allReadingList,
              totalCount: allReadingList.totalCount + 1,
              results: [
                data.createReadingList.readingList,
                ...allReadingList.results,
              ],
            },
          },
        });
        setInputValue('');
      }),
    }).catch((res) => {
      const messages = res.graphQLErrors.map((el) => el.message).join(', ');
      enqueueSnackbar(messages, {
        variant: 'error',
        autoHideDuration: 4000,
      });
    });
  };

  const handleEnterCreate = (e) => {
    if (e.keyCode === 13) {
      handleCreate();
    } else {
      e.stopPropagation();
    }
  };

  return (
    <CreateReadingListField
      {...style}
      id="createReadingListName"
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleEnterCreate}
      inputProps={{
        maxLength: 40,
      }}
      InputProps={{
        startAdornment,
        endAdornment: inputValue.length > 0 ? (
          <IconButton
            aria-label={`create ${readingListTitle.toLowerCase()}`}
            onClick={handleCreate}
            disableRipple
          >
            <DoneFilledSuccessIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label={`new ${readingListTitle}`}
            disableRipple
            disabled
          >
            <AddRectIcon />
          </IconButton>
        ),
      }}
    />
  );
};

CreateReadingList.defaultProps = {
  startAdornment: '',
  placeholder: '',
};

CreateReadingList.propTypes = {
  startAdornment: PropTypes.elementType,
  placeholder: PropTypes.string,
  handleAddInterview: PropTypes.func.isRequired,
};

export default CreateReadingList;

import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  margin: {
    backgroundColor: theme.palette.info.main,
    margin: '5px 0',
  },
}));

const ItemSkeleton = () => {
  const classes = useStyles();
  return (
    <>
      <Skeleton className={classes.margin} variant="rect" height="20px" />
      <Skeleton className={classes.margin} variant="rect" height="20px" />
      <Skeleton className={classes.margin} variant="rect" height="20px" />
      <Skeleton className={classes.margin} variant="rect" height="20px" />
    </>
  );
};

export default ItemSkeleton;

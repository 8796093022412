import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const userStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '20px',
  },
}));

const Perspective = ({ children }) => {
  const classes = userStyles();

  return (
    <Box
      component="span"
      className={classes.root}
    >
      {children}
    </Box>
  );
};

Perspective.defaultProps = {
  children: '',
};

Perspective.propTypes = {
  children: PropTypes.string,
};

export default Perspective;

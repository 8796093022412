// eslint-disable-next-line import/prefer-default-export
export const FILTER_INIT_VALUES = {
  o: null,
  tickers: [],
  industries: [],
  sectors: [],
  keywords: [],
  search: '',
  analyst: [],
  expert: [],
  callDate: null,
  perspective: [],
  complianceStatus: null,
  transcriptionStatus: null,
  publishedAtFrom: null,
  publishedAtTo: null,
  callDateFrom: null,
  callDateTo: null,
  companyName: [],
  readingLists: [],
  isPrimaryTicker: false,
};

import isEmpty from 'lodash/isEmpty';
import { isEqual, isObject, transform } from 'lodash';

export const getFiltersData = (data, target = undefined) => {
  // we use target to prepare data for heap.
  // we need to send uuid to heap instead of id.
  // NEED TO BE VERY CAREFUL WHILE CHANGING THIS FUNCTION as all changes will affect analytic
  const MODEL_FIELDS = ['tickers', 'industries', 'sectors', 'keywords', 'companyName', 'analyst', 'expert', 'title', 'readingLists', 'isPrimaryTicker'];

  const preparedData = {};

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (MODEL_FIELDS.includes(key)) {
        const targetField = target === 'heap' ? 'uuid' : 'id';
        preparedData[key] = value.map((el) => el[targetField]);
      } else if (key === 'perspective') {
        preparedData[key] = value.map((el) => el.perspective);
      }
    } else if (key === 'transcriptionStatus' || key === 'complianceStatus') {
      preparedData[key] = value && value.val;
    } else if (key === 'o') {
      preparedData[key] = value && value.value;
    } else {
      preparedData[key] = value;
    }
  });
  return preparedData;
};

export const getFiltersLabels = (data = {}) => {
  const preparedLabels = [];
  const cleanedData = { ...data };
  delete cleanedData.o;
  delete cleanedData.search;
  delete cleanedData.readingLists;
  delete cleanedData.isPrimaryTicker;

  Object.entries(cleanedData).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (key === 'tickers' || key === 'keywords' || key === 'industries' || key === 'sectors') {
        preparedLabels.push(...value.map((v) => ({
          label: v.title,
          value: v.id,
          filterKey: key,
        })));
      } else if (key === 'companyName') {
        preparedLabels.push(...value.map((v) => ({
          label: v.description,
          value: v.id,
          filterKey: key,
        })));
      } else if (key === 'analyst' || key === 'expert') {
        preparedLabels.push(...value.map((v) => ({
          label: v.shortName,
          value: v.id,
          filterKey: key,
        })));
      } else if (key === 'perspective') {
        preparedLabels.push(...value.map((v) => ({
          label: v.perspective,
          value: v.id,
          filterKey: key,
        })));
      }
      //  else if (key === 'readingLists') {
      //   preparedLabels.push(...value.map((v) => ({
      //     label: v.title,
      //     value: v.id,
      //     filterKey: key,
      //   })));
      // }
    } else if (key === 'transcriptionStatus' || key === 'complianceStatus') {
      preparedLabels.push({
        label: data[key] && data[key].label,
        value: data[key] && data[key].val,
        filterKey: key,
      });
    } else {
      preparedLabels.push({
        label: data[key],
        value: data[key],
        filterKey: key,
      });
    }
  });

  return preparedLabels;
};

export const getOptionsCount = (data) => {
  const cleanedData = { ...data };
  delete cleanedData.o;
  delete cleanedData.search;
  delete cleanedData.readingLists;

  let count = 0;
  Object.values(cleanedData).forEach((value) => {
    if (!isEmpty(value)) {
      if (Array.isArray(value)) {
        count += value.length;
      } else {
        count += 1;
      }
    }
  });
  return count;
};

export const difference = (object, base) => {
  // eslint-disable-next-line no-shadow
  const changes = (object, base) => transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      // eslint-disable-next-line no-param-reassign
      result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
    }
  });

  return changes(object, base);
};

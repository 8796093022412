import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Topbar } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '71px',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('md')]: {
      height: '100vh',
    },
  },
}));

const Main = (props) => {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });

  return (
    <Box
      className={clsx({
        [classes.root]: true,
      })}
    >
      {isMobile
      && <Topbar /> }
      <main className={classes.content}>
        {children}
      </main>
    </Box>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default Main;

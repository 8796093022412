import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { selectAll } from '../../../../actions/bulkSelectActions';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.info.main,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '11px 0',
  },
  text: {
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  selectBtn: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    padding: '0 10px',
    textTransform: 'capitalize',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark,
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
  },
}));

const SelectAllBtn = ({
  interviews, isSelected, isReadingList, userHasPaidAccess,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.bulkSelectedState.selected, isEqual);

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isPrintAllowed = (transcriptionStatus, userCanViewInterview) => isReadingList
        && userCanViewInterview && userHasPaidAccess
          && transcriptionStatus === 'TRANSCRIBED' && isDesktop;

  const handleSelectAll = () => {
    const toSelect = [...selected];

    interviews.forEach((item) => {
      if (!isSelected(item.interviewId)) {
        if ((!isReadingList && item.isSeenInRl === null)
          || (isReadingList
              && isPrintAllowed(item.transcriptionStatus, item.userCanViewInterview))) {
          toSelect.push(item.interviewId);
        }
      }
    });
    if (toSelect.length === 0) {
      enqueueSnackbar('No items to select.', { variant: 'error', autoHideDuration: 4000 });
    } else {
      dispatch(selectAll(toSelect));
    }
  };

  return (
    <Box width="100%" id="select-all-btn">
      <Box
        display={selected.length > 0 ? 'block' : 'none'}
        className={classes.container}
      >
        <Box className={classes.textBox}>
          <Box className={classes.text}>
            {selected.length}
            &nbsp;interviews selected out of
            {' '}
            <b>{interviews && interviews.length}</b>
          </Box>
          <Button
            onClick={handleSelectAll}
            className={classes.selectBtn}
            disableRipple
          >
            Select all
          </Button>
        </Box>

      </Box>
    </Box>
  );
};

SelectAllBtn.propTypes = {
  isSelected: PropTypes.func.isRequired,
  interviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  isReadingList: PropTypes.bool.isRequired,
  userHasPaidAccess: PropTypes.bool.isRequired,
};

export default SelectAllBtn;

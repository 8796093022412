import React from 'react';
import { Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  errorMsg: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.error.main,
  },
}));

const FormCheckboxField = ({
  fieldName, label, children, ...rest
}) => {
  const classes = useStyles();

  return (
    <Field name={fieldName} type="checkbox">
      {({ input, meta }) => (
        <Box>
          <Box
            display="flex"
            alignItems={{ xs: 'flex-start', md: 'center' }}
          >
            <Checkbox
              {...input}
              id={`id_${fieldName}`}
              {...rest}
            />

            {children && children}

            {label
            && <label htmlFor={`id_${fieldName}`} className={classes.label}>{label}</label>}
          </Box>
          {(meta.error || meta.submitError) && meta.touched && (
            <span className={classes.errorMsg}>{meta.error || meta.submitError}</span>
          )}
        </Box>
      )}
    </Field>
  );
};

FormCheckboxField.defaultProps = {
  label: '',
  children: '',
};

FormCheckboxField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  children: PropTypes.string,
};

export default FormCheckboxField;

import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TRACK_ACTION_MUTATION = gql`
mutation trackAction($input: TrackActionMutationInput!){
  trackAction(input: $input){
    success
  }
}
`;

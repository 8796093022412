import React, { useEffect } from 'react';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import useMe from '../hooks/useMe';

const optimizely = createInstance({
  sdkKey: `${process.env.REACT_APP_OPTIMIZELY_KEY}`,
});

const OptimizelyHOC = ({ children }) => {
  const { me } = useMe();

  useEffect(() => {
    if (!isEmpty(me)) {
      optimizely.setUser({ id: `${me.id}` });
    }
  }, [me]);

  return (
    <OptimizelyProvider
      optimizely={optimizely}
    >
      {children}
    </OptimizelyProvider>
  );
};

OptimizelyHOC.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OptimizelyHOC;

import React from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { DOWNLOAD_INTERVIEW_PDF_MUTATION } from '../../queries/interview/interview';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdfIcon.svg';
import { clearAll } from '../../actions/bulkSelectActions';

function b64toBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'application/pdf' });
}

const PrintBtn = ({
  selected, className, children,
}) => {
  const [download] = useMutation(DOWNLOAD_INTERVIEW_PDF_MUTATION);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: false,
  });
  const dispatch = useDispatch();

  const handleClick = () => {
    if (selected.length <= 10) {
      enqueueSnackbar('PDF will be prepared and downloaded. Do not leave the page!', { variant: 'success', autoHideDuration: 4000 });
      download({ variables: { interviewIds: selected } }).then((result) => {
        const blob = b64toBlob(`data:application/pdf;base64,${result.data.downloadInterviewPdf.file}`);
        const fileName = result.data.downloadInterviewPdf.filename;
        if (navigator.msSaveBlob) {
          return navigator.msSaveBlob(blob, fileName);
        }
        const url = URL.createObjectURL(blob);
        if (isMobile) {
          window.location = url;
        } else {
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
      dispatch(clearAll());
    } else {
      enqueueSnackbar('You can select a maximum of 10 transcripts to print', { variant: 'error', autoHideDuration: 4000 });
    }
  };

  return (
    <Tooltip title={children || 'Save as PDF'} arrow>
      <Box
        className={className}
        onClick={handleClick}
      >
        <PdfIcon />
      </Box>
    </Tooltip>
  );
};

PrintBtn.defaultProps = {
  className: '',
  children: null,
};

PrintBtn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrintBtn;

import {
  ADD_ITEM, REMOVE_ITEM, SELECT_ALL, CLEAR_ALL,
} from '../actions/bulkSelectActions';

const initialState = {
  selected: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM: {
      const result = [...state.selected, action.payload.itemId];
      return {
        ...state,
        selected: result,
      };
    }
    case REMOVE_ITEM: {
      const result = state.selected.filter((e) => e !== action.payload.itemId);
      return {
        ...state,
        selected: result,
      };
    }
    case CLEAR_ALL: {
      return {
        ...state,
        selected: [],
      };
    }
    case SELECT_ALL: {
      return {
        ...state,
        selected: action.payload.itemIds,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  outRect: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid',
    borderColor: theme.palette.info.main,
    borderRadius: '5px',
    marginBottom: '5px',
    padding: '9px',
  },
  inRect: {
    backgroundColor: theme.palette.info.main,
    borderRadius: '5px',
  },
}));

const ItemsSkeleton = () => {
  const classes = useStyles();

  return (
    <>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
      <Skeleton className={classes.outRect} variant="rect" width="100%" height="30px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Skeleton className={classes.inRect} variant="rect" width="110px" height="10px" />
          <Skeleton className={classes.inRect} variant="rect" width="50px" height="10px" />
        </Box>
      </Skeleton>
    </>
  );
};

export default ItemsSkeleton;

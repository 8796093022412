import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Hidden from '@material-ui/core/Hidden';
import { Footer, Topbar } from './components';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 'calc(100vh - 100px)',
    backgroundColor: theme.palette.background.default,
  },
}));

const Main = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Hidden mdUp>
        <Topbar />
      </Hidden>
      <main className={classes.content}>
        {children}
      </main>
      <Footer />
    </Box>
  );
};

Main.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export default Main;

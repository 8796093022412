/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as Cancel2Icon } from '../../assets/icons/cancel2Icon.svg';
import { ReactComponent as DoneFilledIcon } from '../../assets/icons/doneFilledIcon.svg';
import { ReactComponent as EmptyRectIcon } from '../../assets/icons/emptyRectIcon.svg';

const useStyles = makeStyles((theme) => ({
  clearBtn: {
    minWidth: '16px',
    height: '100%',
    padding: '0',
    marginRight: '5px',
    borderRadius: '0',
    backgroundColor: theme.palette.common.white,
    '& .MuiButton-label': {
      padding: '1px',
      '& .MuiButton-startIcon': {
        margin: '0',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      '& svg path': {
        stroke: theme.palette.primary.main,
      },
    },
  },
  openBtn: {
    minWidth: '16px',
    height: '100%',
    padding: '0',
    borderRadius: '0',
    backgroundColor: theme.palette.common.white,
    '& .MuiButton-label': {
      padding: '6px 4px',
      '& .MuiButton-startIcon': {
        margin: '0',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const Label = styled('label')`
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #182341;
  padding-bottom: 5px;
`;

const InputWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  border: 1px solid #EAEEF4;
  border-radius: 5px;
  background-color: #FFFFFF;
  color: #8293A6;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  padding: 5px;
  outline: none;
  transition: all .25s;
  
  &:hover {
    border-color: #BFCCDC;
  }

  &.focused {
    border-color: #1168A7;
  }
  
  &.focused svg.openIcon path {
    stroke: #1168A7;
  }

  & input {
    font-size: 14px;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5px;
    width: 0;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

const Tag = styled(({ label, onDelete, ...props }) => (
  <Box {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </Box>
))`
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  color: #182341;
  background-color: #FFFFFF;
  border: 1px solid #EAEEF4;
  padding: 0 5px;
  outline: 0;
  overflow: hidden;
  
  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  & svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-left: 4px;
    color: #EAEEF4;
  }
  
  &:hover {
    border-color: #BFCCDC;
  }
  
  &:hover svg {
    color: #BFCCDC;
  }
  
  & svg:hover {
    color: #1168A7;
  }
`;

const Listbox = styled('ul')`
  width: 239px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #FFFFFF;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 250px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;
    align-items: center;
    font-weight: bold;

    & span {
      flex-grow: 1;
    }

    & svg {
      margin-right: 10px;
      min-width: 14px;
    }
  }
  
  & li[aria-selected='true'] {
    background-color: #FFFFFF;
    color: #182341;

    & svg path {
      fill: #1168A7;
    }
  }
  
  & li[data-focus='true'] {
    background-color: #1168A7;
    color: #FFFFFF;
    cursor: pointer;

    & svg path {
      fill: #FFFFFF;
    }
    
    & .ticker-desc {
      color: #FFFFFF;
    }
    
    & .ticker-title {
      color: #FFFFFF;
    }
    
    & .span-divider svg path {
      fill: #4080C5;
    }
    
    & .ticker-sector {
      color: #BFCCDC;
    }
  }
`;

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const EraAutocomplete = ({
  limitTags, defaultValue, options, disableCloseOnSelect, label, onChange,
  getOptionLabel, emptyLabel, multiple, hideOpenIcon, hideClearIcon,
  getOptionItem, placeholder, inputClass, ...rest
}) => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    getClearProps,
    getPopupIndicatorProps,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    defaultValue: multiple ? [] : null,
    multiple,
    options,
    disableCloseOnSelect,
    onChange,
    getOptionLabel,
    ...rest,
  });

  const classes = useStyles();

  const renderTags = () => {
    const ariaExpanded = getRootProps()['aria-expanded'];
    if (Array.isArray(value) && value.length !== 0) {
      const selectValue = limitTags ? value.slice(0, limitTags) : value;
      if (value.length > limitTags) {
        return `${value.length} selected`;
      }
      return selectValue.map((option, index) => (
        <Tag label={getOptionLabel(option)} {...getTagProps({ index })} />
      ));
    }
    if (!Array.isArray(value) && value !== null) {
      return <Tag label={getOptionLabel(value)} />;
    }
    return ariaExpanded ? '' : emptyLabel;
  };

  const renderClearButton = () => {
    if ((Array.isArray(value) && value.length !== 0) || value) {
      return (
        <Button
          color="inherit"
          className={classes.clearBtn}
          {...getClearProps()}
          disableRipple
          startIcon={(
            <Cancel2Icon />
          )}
        />
      );
    }
    return '';
  };

  const getItemSize = (index) => {
    const option = groupedOptions[index];
    if (!option) return 0;
    const optionLabel = getOptionLabel(option);
    // eslint-disable-next-line no-mixed-operators
    if (getOptionItem) return getOptionItem(option).optionHeight;
    return Math.ceil(optionLabel.length / 27) * 20 + 12;
  };

  const getHeight = () => {
    const itemCount = groupedOptions.length;
    if (itemCount > 8) {
      return 250;
    }
    return groupedOptions.map((option, index) => getItemSize(index)).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(groupedOptions.length);

  return (
    <NoSsr>
      <Box width="100%">
        <Box {...getRootProps()}>
          {label
          && <Label {...getInputLabelProps()}>{label}</Label>}

          <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
            {multiple && renderTags()}

            <input placeholder={placeholder} className={inputClass} {...getInputProps()} />

            {!hideClearIcon && !isEmpty(value)
            && renderClearButton()}

            {!hideOpenIcon
            && (
              <Button
                color="inherit"
                className={classes.openBtn}
                {...getPopupIndicatorProps()}
                disableRipple
                startIcon={(
                  <svg className="openIcon" width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16683 0.857143L5.00016 5.14286L0.833496 0.857141" stroke="#BFCCDC" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              />
            )}
          </InputWrapper>
        </Box>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            <VariableSizeList
              height={getHeight()}
              width="100%"
              itemSize={getItemSize}
              itemCount={groupedOptions.length}
              ref={gridRef}
              outerElementType={OuterElementType}
            >
              {({ index, style }) => {
                const option = groupedOptions[index];
                const optionProps = getOptionProps({ option, index });

                return (
                  <li {...optionProps} style={style} key={index}>
                    {multiple
                    && (
                      optionProps['aria-selected'] === true
                        ? <DoneFilledIcon />
                        : <EmptyRectIcon />
                    )}
                    {getOptionItem
                      ? getOptionItem(option).layout
                      : <Box component="span">{getOptionLabel(option)}</Box>}
                  </li>
                );
              }}
            </VariableSizeList>
          </Listbox>
        ) : null}
      </Box>
    </NoSsr>
  );
};

EraAutocomplete.defaultProps = {
  limitTags: undefined,
  defaultValue: [],
  disableCloseOnSelect: false,
  emptyLabel: null,
  placeholder: null,
  inputClass: null,
  multiple: false,
  label: '',
  hideOpenIcon: false,
  hideClearIcon: false,
  getOptionItem: false,
};

EraAutocomplete.propTypes = {
  limitTags: PropTypes.number,
  defaultValue: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  disableCloseOnSelect: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  emptyLabel: PropTypes.string,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  multiple: PropTypes.bool,
  hideOpenIcon: PropTypes.bool,
  hideClearIcon: PropTypes.bool,
  getOptionItem: PropTypes.func,
};

export default EraAutocomplete;

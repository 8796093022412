import formatISO from 'date-fns/formatISO';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import {
  dateFormat, dateShortFormat, dateTimeFormat, timeFormat,
} from '../constants/dateFormats';

const handleFormating = (time, formatString) => {
  if (!time) return '';

  let t = time;
  if (typeof time === 'string') {
    t = parseISO(time);
  }
  return format(t, formatString);
};

export const formatDate = (time) => handleFormating(time, dateFormat);
export const formatDateShort = (time) => handleFormating(time, dateShortFormat);
export const formatIsoDate = (time) => time && formatISO(time, { representation: 'date' });
export const formatTime = (time) => handleFormating(time, timeFormat);
export const formatDateTime = (time) => handleFormating(time, dateTimeFormat);

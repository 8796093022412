import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import sideBar from './sideBar';
import filterState from './filterState';
import bulkSelectedState from './bulkSelectedState';
import readingListState from './readingListState';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  sideBar,
  filterState,
  bulkSelectedState,
  readingListState,
});

export default createRootReducer;

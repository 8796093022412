// https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4

function sendToGoogleAnalytics({
  name, delta, value, id,
}) {
  // Assumes the global `gtag()` function exists, see:
  // https://developers.google.com/analytics/devguides/collection/ga4
  // eslint-disable-next-line no-undef
  gtag('event', name, {
    // Use the metric delta as the event's value parameter.
    value: delta,
    // Everything below is a custom event parameter.
    web_vitals_metric_id: id, // Needed to aggregate events.
    web_vitals_metric_value: value, // Optional
    // Any additional params or metadata (e.g. debug information) can be
    // set here as well, within the following limitations:
    // https://support.google.com/analytics/answer/9267744
  });
}

const reportWebVitals = () => {
  if (sendToGoogleAnalytics && sendToGoogleAnalytics instanceof Function) {
    import('web-vitals').then(({
      getCLS, getFID, getFCP, getLCP, getTTFB,
    }) => {
      getCLS(sendToGoogleAnalytics);
      getFID(sendToGoogleAnalytics);
      getFCP(sendToGoogleAnalytics);
      getLCP(sendToGoogleAnalytics);
      getTTFB(sendToGoogleAnalytics);
    });
  }
};

export default reportWebVitals;

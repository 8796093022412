export const callDateNewest = { value: '-call_date', label: 'Call Date - Newest' };
export const callDateOldest = { value: 'call_date', label: 'Call Date - Oldest' };
export const publishedDateNewest = { value: '-published_at', label: 'Published Date - Newest' };
export const publishedDateOldest = { value: 'published_at', label: 'Published Date - Oldest' };
export const mostPopular = { value: '-view_count', label: 'Most Popular' };

const orderingOptions = [
  callDateNewest,
  callDateOldest,
  publishedDateNewest,
  publishedDateOldest,
  mostPopular,
];

export default orderingOptions;

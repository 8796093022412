import React from 'react';
import Box from '@material-ui/core/Box';

const InterviewsNotFound = () => (
  <Box>
    <Box>
      <img alt="empty interviews" src="/images/empty_interviews.svg" />
    </Box>
    <Box
      fontSize="24px"
      fontWeight="bold"
      lineHeight="30px"
      color="text.primary"
      textAlign="center"
    >
      No interviews found
    </Box>
  </Box>
);

export default InterviewsNotFound;

import React from 'react';
import { Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevronDownIcon.svg';

const SelectField = withStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiInputBase-root': {
      marginTop: '8px',
      borderRadius: '0',
      height: '40px',
      minWidth: '200px',
      '& .MuiSelect-root': {
        backgroundColor: theme.palette.common.white,
        padding: '10px 30px 10px 15px',
        minHeight: '18px',
        borderRadius: '0',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '18px',
        color: theme.palette.text.primary,
      },
      '& svg': {
        position: 'absolute',
        right: '10px',
        pointerEvents: 'none',
      },
      '& fieldset': {
        borderColor: theme.palette.info.main,
      },
      '&:hover': {
        '& svg path': {
          stroke: theme.palette.text.secondary,
        },
        '& fieldset': {
          borderColor: theme.palette.info.dark,
        },
      },
      '&.Mui-focused': {
        '& svg path': {
          stroke: theme.palette.primary.main,
        },
        '& fieldset': {
          borderWidth: '1px',
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
}))(FormControl);

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
  },
  item: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
      },
    },
  },
}));

const selectIcon = () => (
  <ChevronDownIcon />
);

const FormSelectField = ({
  fieldName, label, options, ...rest
}) => {
  const classes = useStyles();

  return (
    <Field name={fieldName}>
      {({ input, meta }) => (
        <Box>
          <SelectField>
            <label
              htmlFor={`id_${fieldName}`}
              className={classes.label}
            >
              {label}
            </label>
            <Select
              {...input}
              id={`id_${fieldName}`}
              variant="outlined"
              IconComponent={selectIcon}
              fullWidth
              required
              {...rest}
            >
              {options.map((opt) => (
                <MenuItem
                  key={opt.name}
                  value={opt.name}
                  className={classes.item}
                  disableRipple
                >
                  {opt.description}
                </MenuItem>
              ))}
            </Select>
            {(meta.error || meta.submitError) && meta.touched && (
            <span>{meta.error || meta.submitError}</span>
            )}
          </SelectField>
        </Box>
      )}
    </Field>
  );
};

FormSelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
};

export default FormSelectField;

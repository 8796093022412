import React, {
  useState, useEffect, useCallback,
} from 'react';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { getFiltersLabels, getOptionsCount } from '../../../../helpers/filterHelpers';
import CopyFiltersButton from '../../../../components/CopyFiltersButton/CopyFiltersButton';
// import TopContext from '../../../../context/topContext';
import { writeFilterData } from '../../../../actions/filterActions';

const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '1px 5px',
    marginTop: '3px',
    marginRight: '10px',
    marginBottom: '7px',
    borderRadius: '5px',
  },
  close: {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    marginLeft: '4px',
    color: theme.palette.info.dark,
  },
  checkbox: {
    padding: '0 6px 0 0',
    color: theme.palette.info.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
    '&.Mui-checked': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },
    },
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    color: theme.palette.secondary.main,
  },
}));

const SelectedFilter = ({
// eslint-disable-next-line react/prop-types
  label, filterKey, value, filterData, handleWriteFilterData,
}) => {
  const classes = useStyles();

  const handleDelete = useCallback(() => {
    let data = filterData[filterKey];
    if (Array.isArray(data)) {
      // eslint-disable-next-line react/prop-types
      data = data.filter((el) => el.id !== value);
    } else {
      data = null;
    }
    handleWriteFilterData({ [filterKey]: data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, filterKey, value]);

  return (
    <Box className={classes.filter}>
      {label}
      <CloseIcon className={classes.close} onClick={handleDelete} />
    </Box>
  );
};

const SelectedFilters = () => {
  const classes = useStyles();
  const [result, setResult] = useState([]);
  const [resultCount, setResultCount] = useState(0);
  const activeTab = useSelector((state) => state.sideBar.activeTab, isEqual);
  const filterData = useSelector((state) => state.filterState.filterData, isEqual);

  const dispatch = useDispatch();
  // const context = useContext(TopContext);

  // const { setIsPrimaryTicker } = context;

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    dispatch(writeFilterData({ isPrimaryTicker: isChecked }));
  };
  const handleUpdateFilterData = (data) => {
    dispatch(writeFilterData(data));
  };
  useEffect(() => {
    setResult(getFiltersLabels(filterData));
    setResultCount(getOptionsCount(filterData));
  }, [filterData]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
      >
        {resultCount > 0
        && (
          <>
            <Box
              fontSize="14px"
              fontWeight="bold"
              lineHeight="18px"
              color="text.primary"
              mt="3px"
              mr="10px"
              mb="7px"
            >
              Filters:
            </Box>
            {result.map((el) => (
              el.label
              && (
              <SelectedFilter
                key={`${el.filterKey}${el.value}`}
                label={el.label}
                value={el.value}
                filterKey={el.filterKey}
                handleWriteFilterData={handleUpdateFilterData}
                filterData={filterData}
              />
              )
            ))}
            {filterData.tickers.length > 0
            && (
              <Box
                display="flex"
                flexGrow="1"
                justifyContent="flex-end"
                flexWrap="nowrap"
                fontSize="14px"
                fontWeight="bold"
                lineHeight="18px"
                color="text.primary"
                mb="5px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                >
                  <Checkbox
                    id="isPrimaryTickerOnly"
                    disableRipple
                    className={classes.checkbox}
                    checked={filterData.isPrimaryTicker}
                    onChange={(e) => handleChange(e)}
                  />
                  <label htmlFor="isPrimaryTickerOnly" className={classes.label}>Primary Ticker Only</label>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      {resultCount > 0 && activeTab === 'companies' && <CopyFiltersButton /> }
    </>
  );
};

export default SelectedFilters;

const white = '#FFFFFF';
const black = '#182341';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#0A568C',
    main: '#1168A7',
    light: '#1C82CC',
  },
  secondary: {
    contrastText: white,
    dark: '#001153',
    main: '#0F3580',
    light: '#4C5EB0',
  },
  success: {
    contrastText: white,
    dark: '#539C00',
    main: '#88CD00',
    light: '#BDFF4D',
  },
  info: {
    contrastText: black,
    dark: '#BFCCDC',
    main: '#EAEEF4',
    light: '#F4F6FA',
  },
  warning: {
    contrastText: white,
    dark: '#D3600A',
    main: '#EE6E0D',
    light: '#F98127',
  },
  error: {
    contrastText: white,
    dark: '#930000',
    main: '#CC0000',
    light: '#FF4F30',
  },
  text: {
    primary: '#182341',
    secondary: '#8293A6',
    link: '#1168A7',
  },
  background: {
    default: '#F4F6FA',
    paper: white,
  },
  icon: '#BFCCDC',
  divider: '#EAEEF4',
};

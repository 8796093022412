// eslint-disable-next-line import/prefer-default-export
export const searchToObject = () => {
  const pairs = window.location.search.substring(1).split('&');
  const obj = {};
  let pair;
  let i;

  // eslint-disable-next-line no-restricted-syntax
  for (i in pairs) {
    // eslint-disable-next-line no-continue
    if (pairs[i] === '') continue;

    pair = pairs[i].split('=');
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};

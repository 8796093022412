import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import LogRocket from 'logrocket';
import isEmpty from 'lodash/isEmpty';
import { formatDate } from '../../helpers/dateHelpers';
import useMe from '../../hooks/useMe';
import useReadingListTitle from '../../hooks/useReadingListTitle';

if (process.env.REACT_APP_TARGET === 'prod') {
  LogRocket.init('orsrdp/stream');
} else if (process.env.REACT_APP_TARGET === 'dev') {
  LogRocket.init('orsrdp/stream-dev');
} else if (process.env.REACT_APP_TARGET === 'hellcat') {
  LogRocket.init('orsrdp/stream-hellcat');
}

const Integrations = () => {
  const { me } = useMe();
  const { boot, update } = useIntercom();

  useEffect(() => {
    boot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // user data for intercom
    if (isEmpty(me)) return;

    update({
      name: me.realFullName || '',
      first_name: me.firstName || '',
      last_name: me.lastName || '',
      email: me.email || '',
      created_at: me.createdAt ? formatDate(me.createdAt) : '',
      uuid: me.uuid,
      user_is_trial: me.isTrial !== undefined ? me.isTrial : false,
      user_is_paid: me.isPaid !== undefined ? me.isPaid : false,
      user_is_bonus: me.isBonus !== undefined ? me.isBonus : false,
      user_is_staff: me.isStaff !== undefined ? me.isStaff : false,
      user_is_internal: me.isInternal !== undefined ? me.isInternal : false,
      user_role: me.role ? me.role.toLowerCase() : '',
      trial_start_date: me.trialStartDate || '',
      trial_expire_date: me.trialExpireDate || '',
      become_paid_at: me.becomePaid || '',
      watchlist_count: (me.sectorWatchlistCount
        + me.companyWatchlistCount
        + me.industryWatchlistCount
        + me.keywordWatchlistCount) || 0,
      account_members: me.accountMembersCount || 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const resolvePaidStatus = (isPaid, isBonus) => {
    if (isPaid === undefined && isBonus === undefined) return '';
    return isPaid || isBonus ? 'Paid' : 'Free';
  };

  if (process.env.REACT_APP_TARGET === 'prod') {
    LogRocket.identify(me ? me.id : 'None', {
      name: me.realFullName || '',
      email: me.email || '',
      role: me.roleDisplay || '',
      subscriptionType: resolvePaidStatus(me.isPaid, me.isBonus),
      isConsentToReceiveInfo: me.isConsentToReceiveInfo || '',
      isStaff: me.isStaff !== undefined ? me.isStaff : '',
      isInternal: me.isInternal !== undefined ? me.isInternal : '',
    });
  }

  // push data to heap
  useEffect(() => {
    window.dataLayer.push({
      event: 'userUpdate',
      uuid: me.uuid,
      user_is_trial: me.isTrial !== undefined ? me.isTrial : false,
      user_is_paid: me.isPaid !== undefined ? me.isPaid : false,
      user_is_bonus: me.isBonus !== undefined ? me.isBonus : false,
      user_is_staff: me.isStaff !== undefined ? me.isStaff : false,
      user_is_internal: me.isInternal !== undefined ? me.isInternal : false,
      user_role: me.role ? me.role.toLowerCase() : '',
      trial_start_date: me.trialStartDate || '',
      trial_expire_date: me.trialExpireDate || '',
      become_paid_at: me.becomePaid || '',
      watchlist_count: (me.sectorWatchlistCount
                      + me.companyWatchlistCount
                      + me.industryWatchlistCount
                      + me.keywordWatchlistCount) || 0,
      account_members: me.accountMembersCount || 0,
    });
  }, [me]);

  const readingListTitle = useReadingListTitle();

  window.analytics.identify(me.uuid, {
    name: me.realFullName || '',
    email: me.email || '',
    XP_reading_list_title: readingListTitle,
  });

  return null;
};

export default Integrations;

import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { INTERVIEW_QUERY, MEMBER_ACCESS_REQUEST } from '../../queries/interview/interview';
import { searchToObject } from '../../helpers/locationSearchToJson';
import useMe from '../../hooks/useMe';

const useStyles = makeStyles(() => ({
  root: {
    fontFamily: 'PT Serif',
  },
  accessRequest: {
    //
  },
  postBox: {
    backgroundColor: '#1168A7',
    padding: '50px 20px',
    margin: '20px 0',
    color: '#fff',
    textAlign: 'center!important',
  },
  accessRequestHeadline: {
    // fontFamily: 'PT Serif',
    marginBottom: '20px',
    color: '#FFFFFF',
    fontSize: '20px',
    lineHeight: '26px',
    marginTop: '0',
    fontWeight: '700!important',
  },
  accessRequestButton: {
    height: '40px',
    color: '#182341',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '18px',
    padding: '11px 20px',
    background: '#fff',
    borderColor: '#1168A7',
    borderRadius: '0px',
    textTransform: 'none',
    transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out',
    '&:hover': {
      background: '#DAEFFF',
    },
  },
}));

const MemberAccessRequest = ({ interviewId }) => {
  const classes = useStyles();
  const location = useLocation();
  const { trackEvent } = useTracking();
  const { me } = useMe();

  const [createRequest, { loading, called }] = useMutation(MEMBER_ACCESS_REQUEST, {
    errorPolicy: 'all',
    refetchQueries: [{ query: INTERVIEW_QUERY, variables: { interviewId } }],
  });

  const handleRequest = () => {
    if (loading || called) return;

    createRequest({ variables: { interviewId } });
    trackEvent({
      viewName: 'interview:request_access_interview',
      path: location.pathname,
      getDict: JSON.stringify(searchToObject(location.search)),
      extraData: JSON.stringify({ interviewId }),
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.accessRequest}>
        <Box className={classes.postBox}>
          <Box className={classes.accessRequestHeadline}>
            {me.role === 'EXPERT'
              ? (
                'Request access to this interview'
              )
              : (
                'Request access to this interview from your Compliance Officer'
              )}
          </Box>
          <Button
            onClick={handleRequest}
            className={classes.accessRequestButton}
            disabled={loading || called}
          >
            Request Now
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

MemberAccessRequest.propTypes = {
  interviewId: PropTypes.string.isRequired,
};

export default MemberAccessRequest;

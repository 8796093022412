import {
  WRITE_DATA, CLEAN_FILTERS,
} from '../actions/filterActions';
import { getFiltersData, difference } from '../helpers/filterHelpers';
import { FILTER_INIT_VALUES } from '../constants/filterInitValues';

const initialState = {
  filterData: FILTER_INIT_VALUES,
  previousFilterData: FILTER_INIT_VALUES,
  filterDelta: {},
};

const getDelta = (filterData, previousFilterData) => (
  getFiltersData(difference(filterData, previousFilterData), 'heap')
);

export default (state = initialState, action) => {
  switch (action.type) {
    case WRITE_DATA: {
      const updatedFilterData = { ...state.filterData, ...action.payload };
      if (getDelta(state.filterData, updatedFilterData).tickers) {
        updatedFilterData.search = FILTER_INIT_VALUES.search;
      }
      return {
        ...state,
        filterData: updatedFilterData,
        previousFilterData: state.filterData,
        filterDelta: getDelta(state.filterData, updatedFilterData),
      };
    }
    case CLEAN_FILTERS:
      return {
        ...state,
        filterData: FILTER_INIT_VALUES,
      };
    default:
      return state;
  }
};
